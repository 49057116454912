import React, { useState } from 'react';
import { postJobListing } from './apiService';
import './AdminDashboard.css';

function AdminDashboard() {
  const [formData, setFormData] = useState({
    company: '',
    datePosted: '',
    batch: '',
    link: '',
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let formErrors = {};
    if (!formData.company) formErrors.company = 'Company name is required.';
    if (!formData.datePosted) formErrors.datePosted = 'Date posted is required.';
    if (!formData.batch) formErrors.batch = 'Batch is required.';
    if (!formData.link) formErrors.link = 'Job link is required.';
    else if (!/^https?:\/\/[^\s]+$/.test(formData.link)) formErrors.link = 'Please enter a valid URL.';

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      postJobListing(formData)
        .then((data) => {
          console.log('Job listing posted:', data);
          // Clear the form after successful submission
          setFormData({
            company: '',
            datePosted: '',
            batch: '',
            link: '',
          });
          setErrors({});
        })
        .catch((error) => {
          console.error('Error posting job listing:', error);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="admin-dashboard">
      <h2>Admin Dashboard</h2>
      <form onSubmit={handleSubmit} className="job-form">
        <div className="form-group">
          <label>Company:</label>
          <input
            type="text"
            name="company"
            value={formData.company}
            onChange={handleChange}
            className={errors.company ? 'input-error' : ''}
          />
          {errors.company && <span className="error-message">{errors.company}</span>}
        </div>
        <div className="form-group">
          <label>Date Posted:</label>
          <input
            type="date"
            name="datePosted"
            value={formData.datePosted}
            onChange={handleChange}
            className={errors.datePosted ? 'input-error' : ''}
          />
          {errors.datePosted && <span className="error-message">{errors.datePosted}</span>}
        </div>
        <div className="form-group">
          <label>Batch:</label>
          <input
            type="text"
            name="batch"
            value={formData.batch}
            onChange={handleChange}
            className={errors.batch ? 'input-error' : ''}
          />
          {errors.batch && <span className="error-message">{errors.batch}</span>}
        </div>
        <div className="form-group">
          <label>Link:</label>
          <input
            type="text"
            name="link"
            value={formData.link}
            onChange={handleChange}
            className={errors.link ? 'input-error' : ''}
          />
          {errors.link && <span className="error-message">{errors.link}</span>}
        </div>
        <button type="submit" className="submit-button">Post Job Listing</button>
      </form>
    </div>
  );
}

export default AdminDashboard;
