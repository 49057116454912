import React, { useEffect, useState } from 'react';
import { getJobListings } from './apiService';

function Content() {
  const [jobListings, setJobListings] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [viewApplied, setViewApplied] = useState(false); // State to toggle between job listings and applied jobs

  useEffect(() => {
    getJobListings()
      .then((data) => setJobListings(data.jobListings))
      .catch((error) => console.error('Error fetching job listings:', error));

    const savedAppliedJobs = JSON.parse(localStorage.getItem('appliedJobs')) || [];
    setAppliedJobs(savedAppliedJobs);
  }, []);

  const handleApply = (jobId) => {
    if (!appliedJobs.includes(jobId)) {
      const updatedAppliedJobs = [...appliedJobs, jobId];
      setAppliedJobs(updatedAppliedJobs);
      localStorage.setItem('appliedJobs', JSON.stringify(updatedAppliedJobs));
    }
  };

  return (
    <div className="content-container">
      <div className="view-toggle">
        <button onClick={() => setViewApplied(false)} className={!viewApplied ? 'active' : ''}>
          Job Listings
        </button>
        <button onClick={() => setViewApplied(true)} className={viewApplied ? 'active' : ''}>
          Applied Jobs
        </button>
      </div>
      <h2>{viewApplied ? 'Applied Jobs' : 'Job Listings'}</h2>
      <ul className="job-list">
        {jobListings
          .filter((listing) => (viewApplied ? appliedJobs.includes(listing.id) : true))
          .map((listing) => (
            <li key={listing.id} className={`job-item ${appliedJobs.includes(listing.id) ? 'applied' : ''}`}>
              <div className="job-image">
                <img src="/sample.jpg" alt={`${listing.company} logo`} />
              </div>
              <div className="job-details">
                <h3>{listing.company}</h3>
                <p>Date Posted: {listing.datePosted}</p>
                <p>Batch: {listing.batch}</p>
                {appliedJobs.includes(listing.id) ? (
                  <span className="applied-label">Applied</span>
                ) : (
                  !viewApplied && <button onClick={() => handleApply(listing.id)}>Apply</button>
                )}
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default Content;
