import React, { useEffect, useState } from 'react';
import { getJobListings } from './apiService';

function AppliedJobs() {
  const [jobListings, setJobListings] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);

  useEffect(() => {
    // Fetch job listings when the component mounts
    getJobListings()
      .then((data) => setJobListings(data.jobListings))
      .catch((error) => console.error('Error fetching job listings:', error));

    // Load applied jobs from local storage
    const savedAppliedJobs = JSON.parse(localStorage.getItem('appliedJobs')) || [];
    setAppliedJobs(savedAppliedJobs);
  }, []);

  return (
    <div className="content-container">
      <h2>Applied Jobs</h2>
      <ul className="job-list">
        {jobListings
          .filter((listing) => appliedJobs.includes(listing.id))
          .map((listing) => (
            <li key={listing.id} className="job-item applied">
              <h3>{listing.company}</h3>
              <p>Date Posted: {listing.datePosted}</p>
              <p>Batch: {listing.batch}</p>
              <a href={listing.link} target="_blank" rel="noopener noreferrer">
                View Job
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default AppliedJobs;
